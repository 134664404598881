import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';
import {
	BillingService,
	Subscription as BillingOrder,
	AccountSubscriptionCollection, 
	RatePlanInfo,
	RatePlanCharge,
	BillingAccount,
	PartnerSubscriptionCollection,
	PartnerSubscriptionCollectionEmbedded
} from '@mitel/cloudlink-sdk/billing';
import { ErrorsHandlerService } from './errors-handler.service';
import { environment } from '../../environments/environment';
import { StatusEnum } from '../enums/subscription.enum';

export const IntegToLicenseCrossreference = 
[
	{
		integProductCode: "MiTeam",
		licenseIdInBilling: environment.subscriptionIds.MiTeam
	},
	{
		integProductCode: "ContactCenter",
		licenseIdInBilling: environment.subscriptionIds.ContactCenter
	},
	{
		integProductCode: "MitelOne",
		licenseIdInBilling: environment.subscriptionIds.MitelOne
	},
	{
		integProductCode: "MiVoice5000",
		licenseIdInBilling: environment.subscriptionIds.MiVoice5000
	},
	{
		integProductCode: "MiVB",
		licenseIdInBilling: environment.subscriptionIds.MiVoiceBusiness
	},
	{
		integProductCode: "Zappa",
		licenseIdInBilling: environment.subscriptionIds.ZoomHybrid
	}
]
export class LicenseItem {
	productName: string;
	productId: string;
	productDetails: string;
	numLicenses: string;
	ratePlanInfo: string;
}

export class OrderItem {
	id: string;
	orderNumber: string;
	subscriptionNumber: string;
	accountId: string;
	accountName: string;
	accountNumber: string;
	orderDate: string;
	orderDateOrigFormat: string;
	orderExpiry: string;
	unassigned: boolean;
	expiryDays?: number;
	expired?: boolean;
	undeployed?: boolean;
	canActivate?: boolean;
	perpetual?: boolean;
	expiring: boolean;
	isPending: boolean;
	poNumber: string;
	customerPoNumber: string;
	customerSapId: string;
	resellerSapId?: string;
	sapOrderId?: string;
	term: string;
	licenses: LicenseItem[];
};

@Injectable({
	providedIn: 'root'
})
export class OrdersService {

	public ordersListChanged = new Subject<OrderItem[]>();

	private orders: OrderItem[] = [];

	private billingAccounts: BillingAccount[];

	private readonly billingService: BillingService; 

	constructor( private translateSvc: TranslateService,
			private authService: AuthService,
			private errService: ErrorsHandlerService ) 
	{
		this.billingService = new BillingService({
			// version: '2019-03-01'
			// cloud: 'dev',
			// stage: 'stage'
		  });
	}

	//odata is not implemented for getSubacriptions odata?: Odata
	getAllOrders( partnerAccountId?: string  ): Promise<OrderItem[]> {
		let params = {};
		let orderList: OrderItem[] = [];
		return this.billingService.getPartnerSubscriptions(params).then( ordersCollection => {
			if ( ordersCollection && 
				 ordersCollection.count > 0 && 
				 ordersCollection._embedded && 
				 ordersCollection._embedded.items ) 
			{
				//this data is a list of accounts with their subscriptions = 
				let partnerCollectionList: AccountSubscriptionCollection[] = ordersCollection._embedded.items;

				console.log("The subscriptions from the back end", partnerCollectionList);
				for ( var i = 0; i < partnerCollectionList.length; i++ )
				{
					let acctSubscriptionInfo: AccountSubscriptionCollection = partnerCollectionList[i];
					if ( acctSubscriptionInfo.accountSubscriptions && 
						acctSubscriptionInfo.accountSubscriptions.count > 0 && 
						acctSubscriptionInfo.accountSubscriptions._embedded && 
						acctSubscriptionInfo.accountSubscriptions._embedded.items )
					{
						//let acctSubList: BillingOrder[] = acctSubscriptionInfo.accountSubscriptions._embedded.items;
						// use any until update to object  BillingOrder is available
						let acctSubList: any = acctSubscriptionInfo.accountSubscriptions._embedded.items; 

						for ( var j = 0; j < acctSubList.length; j++ )
						{
							let newOrder = new OrderItem();
							newOrder.id = acctSubList[j].id;
							// was told not to use contractEffectiveDate. Use termStartDate instead
							newOrder.orderDateOrigFormat = acctSubList[j].termStartDate;
							newOrder.orderDate = this.formatDate(acctSubList[j].termStartDate);
							newOrder.orderNumber = acctSubList[j].cpqTrackingId ? acctSubList[j].cpqTrackingId : acctSubList[j].orderNumber;
							newOrder.subscriptionNumber = acctSubList[j].subscriptionNumber;
							newOrder.isPending = false;
							newOrder.accountId = acctSubscriptionInfo.accountId;
							newOrder.accountNumber = acctSubscriptionInfo.accountNumber; 
							if ( partnerAccountId && partnerAccountId == acctSubscriptionInfo.accountId)
							{
								newOrder.accountName = '';
								newOrder.unassigned = true;
								if (acctSubList[j].status === StatusEnum.PENDING_ACTIVATION)
								{
									// only show pending activation if the account is assigned to the partner (i.e. is unassigned)
									newOrder.isPending = true;
								}
							}
							else if (!acctSubscriptionInfo.accountId || acctSubscriptionInfo.accountId == '')
							{
								newOrder.accountName = '';
								newOrder.undeployed = true;
							}
							else
							{
								newOrder.accountName = acctSubscriptionInfo.accountName;
								newOrder.unassigned = false;
								newOrder.undeployed = false;
								if (acctSubList[j].status === StatusEnum.PENDING_ACTIVATION) {
									newOrder.canActivate = true;
								}
							}

							newOrder.poNumber = (acctSubList[j].resellerPoNumber ? acctSubList[j].resellerPoNumber : ''); 
							newOrder.customerPoNumber = (acctSubList[j].customerPoNumber ? acctSubList[j].customerPoNumber : '');
							newOrder.customerSapId = (acctSubList[j].customerSapId ? acctSubList[j].customerSapId : ''); 
							newOrder.resellerSapId = (acctSubList[j].resellerSapId ? acctSubList[j].resellerSapId : '');
							newOrder.sapOrderId = (acctSubList[j].sapOrderId ? acctSubList[j].sapOrderId : '');

							if ( acctSubList[j].termType == "EVERGREEN" )
							{
								newOrder.term = this.translateSvc.instant('orders_list.perpetual');
								newOrder.perpetual = true;
								newOrder.orderExpiry = null;
							}
							else
							{
								newOrder.term = ( acctSubList[j].currentTerm ? 
									acctSubList[j].currentTerm + " " + this.translateSvc.instant('order_details.' + acctSubList[j].currentTermPeriodType.toLowerCase()) :
									this.translateSvc.instant('order_details.unknown') );
								newOrder.perpetual = false;
								newOrder.orderExpiry = null;
								if ( acctSubList[j].termEndDate != undefined )
								{
									newOrder.orderExpiry = this.formatDate(acctSubList[j].termEndDate);
									let numDays = this.calculateDaysToExpiry(acctSubList[j].termEndDate);
									if ( numDays == 0 )
										newOrder.expired = true;
									else if ( numDays > 0 )
									{
										newOrder.expiring = true;
										newOrder.expiryDays = numDays;
									}
								}
							}

							newOrder.licenses = this.createLicenseListForSubscription( acctSubList[j] );

							orderList.push(newOrder);
						}
					}
				}
			}
			return orderList;
		})
		.catch(err => {
			this.errService.handleError(err);
			throw err;
		});
		// this.orders = this.tempOrders;
		// return Promise.resolve(this.orders);
	}

	// 90 days is the current expiry
	private expiryInMilliseconds: number = 90*24*60*60*1000; //330*24*60*60*1000; 
	private calculateDaysToExpiry( endDateStr: string )
	{
		let d = new Date();
		d.setHours(0,0,0,0); // compare at midnight since we are interested in dates
		let currentTime = d.getTime();
		let specifiedDate = Date.parse( endDateStr + " 00:00:00" ); // compare at midnight since we are interested in dates
		let timeGap = specifiedDate - currentTime;
		if ( timeGap < this.expiryInMilliseconds )
		{
			if ( timeGap < 0 )
				return 0;
			else
			{
				let days = Math.trunc(timeGap/(24*60*60*1000));
				return days;
			}
		}
		return -1;
	}

	private createLicenseListForSubscription( subsItem: BillingOrder) : LicenseItem[]
	{
		let licenseList: LicenseItem[] = [];
		if ( subsItem._embedded &&
			subsItem._embedded.ratePlans &&
			subsItem._embedded.ratePlans.count > 0 && 
			subsItem._embedded.ratePlans._embedded &&
			subsItem._embedded.ratePlans._embedded.items )
		{
			let ratePlans: RatePlanInfo[] = subsItem._embedded.ratePlans._embedded.items;

			for ( var k = 0; k < ratePlans.length; k++ )
			{
				if ( ratePlans[k]._embedded &&
					ratePlans[k]._embedded.charges &&
					ratePlans[k]._embedded.charges.count > 0 &&
					ratePlans[k]._embedded.charges._embedded &&
					ratePlans[k]._embedded.charges._embedded.items )
				{
					if ( ratePlans[k].lastChangeType != "Remove" )
					{
						let charges: RatePlanCharge[] = ratePlans[k]._embedded.charges._embedded.items;

						for ( var l = 0; l < charges.length; l++ )
						{
							// not sure this is the final fix
							// if ( charges[l].type === 'Recurring' )
							// {
								let tempSubItem: any = subsItem; // because I need a field not in the SDK yet

								let lItem : LicenseItem = new LicenseItem();
								lItem.numLicenses = charges[l].quantity;
								lItem.productDetails = ratePlans[k].name ? ratePlans[k].name : '';
								lItem.productName = ratePlans[k].productName; 
								lItem.productId = ratePlans[k].productId;
								lItem.ratePlanInfo = charges[l].name;
								licenseList.push(lItem);
							// }
						}
					}
				}

			}
		}
		return licenseList;
	}

	// based on a known format
	// is this future proof?
	private formatDate( dateStr: string ): string {
		let dateparts = dateStr.split('-');
		return dateparts[1] + '/' + dateparts[2] + '/' + dateparts[0].substr(2);
	}

	assignOrderToAccount( order: OrderItem, accountNumber: string): Promise<void>
	{
		let req : any = { //OrderCreateRequest = {  use any until sdk is updated
			orderDate: order.orderDateOrigFormat,  
			existingAccountNumber: order.accountNumber,
			subscriptions: [
				{
					orderActions: [
						{
							type: "AssignSubscription",
							triggerDates: [
								{
									name: "ContractEffective",
									triggerDate: order.orderDateOrigFormat,  
								}
							],
							ownerTransfer: { 
								destinationAccountNumber: accountNumber,
								destinationInvoiceAccountNumber: order.accountNumber
							}
						}
					],
					subscriptionNumber: order.subscriptionNumber
				}
			]
		}
		return this.billingService.createOrder({body: req}).then( info => {
			return;
		})
		.catch(err => {
			this.errService.handleError(err);
			throw err;
		});
		//return Promise.resolve();
	}

	/**
	 * @returns the accountId of the account created for the order
	 */
	async deployOrder( order: OrderItem ): Promise<string> {
		let body: any = {
			subscriptions: [{
				orderActions: [{
					type: "DeploySubscription",
					deploySubscription: {
						subscriptionNumber: order.subscriptionNumber
					}
				}],
			}]
		}
		return this.billingService.createOrder({body: body})
		.then((response: any) => response?.accountId)
		.catch(err => {
			this.errService.handleError(err);
			throw err;
		});
	}

	async activateOrder( order: OrderItem ): Promise<any> {
		let body: any = {
			orderDate: order.orderDateOrigFormat, // not sure if we need this yet and what it should be
			subscriptions: [{
				orderActions: [{
					type: "ActivateSubscription",
					activateSubscription: {
						subscriptionNumber: order.subscriptionNumber
					}
				}],
			}]
		}
		return this.billingService.createOrder({body: body})
		.catch(err => {
			this.errService.handleError(err);
			throw err;
		});
	}

		// Billing accounts no longer exist for customers
		// keeping this method until a SAP Id solution is created
	/* istanbul ignore next */ 	
	getBillingAccounts(): Promise<BillingAccount[]> {
		return Promise.resolve([]);

		// Billing accounts no longer exist for customers
		// return new Promise<BillingAccount[]>( async( resolve, reject) =>{
		// 	if (this.billingAccounts) {
		// 		resolve([...this.billingAccounts]);
		// 	} else {
		// 		try {
		// 			const billingAccountsCollection = await this.billingService.getBillingAccounts({});
		// 			if (billingAccountsCollection && billingAccountsCollection.count > 0 
		// 				&& billingAccountsCollection._embedded && billingAccountsCollection._embedded.items) {
		// 					this.billingAccounts = billingAccountsCollection._embedded.items;
		// 					resolve([...this.billingAccounts]);
		// 			} else {
		// 				resolve([]);	
		// 			}
		// 		} catch( error) {
		// 			console.log("Failed to retrieve all billing accounts", error);
		// 			resolve([]);
		// 		}
				
		// 	}
		// });
	}
}
