import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ParentCommsService } from '../../services/parent-comms.service';

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.scss']
})

export class OrderCompleteComponent {

  showFormError = false;
  errMsg = '';
  errMsgStyle: any = { 'max-width': '500px' };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<OrderCompleteComponent>,
    public translateSvc: TranslateService,
    private parentCommsService: ParentCommsService
  ) { }

  onClose() {
    this.showFormError = false;
    this.dialogRef.close();
  }

  onForwardToAccount()
  {
    this.showFormError = false;
    if ( this.parentCommsService.tellParentToNavigatToAccountIntegrations(this.data.accountId) )
    {
      this.onClose();
    }
    else{
      this.errMsg = this.translateSvc.instant('order_details.cannot_comm_parent');
      this.showFormError = true;
    }
  }

  onActivateOrder()
  {
    this.showFormError = false;
    this.dialogRef.close({activate: true});
  }

}
