import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppUserClaimsService } from '../../../services/app-user-claims.service';
import { OrderDetailsComponent } from '../../order-details/order-details.component';
import { OrderCompleteComponent } from '../../order-complete/order-complete.component';
import { SpinnerService } from '../../../services/spinner.service';
import { OrdersService, OrderItem, IntegToLicenseCrossreference } from '../../../services/orders.service'; 
import { UserClaims } from '@mitel/cloudlink-sdk';
import { ParentCommsService } from 'src/app/services/parent-comms.service';
import { AdminService } from 'src/app/services/admin.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle.service';
import { FeatureFlag } from 'src/app/enums/feature-flags';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})

export class OrdersListComponent implements OnInit {
	displayedColumns: string[] = ['sapOrderId', 'subscriptionNumber', 'resellerPONumber', 'customerPONumber', 'accountName', 'subscriptionExpiry'];
	dataSource: MatTableDataSource<OrderItem>;
	orders: OrderItem[] = [];
	subscription: Subscription;
	claims: UserClaims;

	showError = false;
  	errMsg = '';
  	errMsgStyle: any = { 'max-width': '100%' };

	 @ViewChild(MatSort, {static: true}) sort: MatSort;

	filters = [{ name: 'all', value: undefined }, { name: 'unassigned', value: true, excludeExpired: false }, { name: 'expiring', value: true }];
	currentFilter = this.filters[0];
	
	constructor(
	  public dialog: MatDialog,
	  private ordersService: OrdersService,
	  private matIconRegistry: MatIconRegistry,
	  private domSanitizer: DomSanitizer,
	  private spinnerService: SpinnerService,
	  private appUserClaimsSvc: AppUserClaimsService,
	  public translateSvc: TranslateService,
	  private parentCommsService: ParentCommsService,
	  private adminService: AdminService,
		private featureToggleService: FeatureToggleService
	) {
		this.matIconRegistry.addSvgIcon(
			'search_app',
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/search-big.svg")
		);
	}


  ngOnInit() {
	  this.spinnerService.showSpinner();
	  this.dataSource = new MatTableDataSource(this.processData([]));
	  if( this.parentCommsService.getCurrentAccountIdToAssignOrder()) {
		  this.currentFilter = this.filters[1];
	  }
	  // Do not want to block the loading of the orders list page
	  setTimeout( ()=> {
		this.populateOrdersList();
	  }, 100);
	}

	isSpinnerShown():boolean {
		return this.spinnerService.isSpinnerShown();
	}

	private populateOrdersList()
	{
		this.subscription = this.appUserClaimsSvc.claimsChanged.subscribe( claims => {
			this.claims = claims;
			this.requestOrdersList();
			this.addFeatureEnabledFilters();
		});
	}

	customSearch( data: OrderItem, filter: string )
	{
		if (data.subscriptionNumber.toLowerCase().indexOf(filter.toLowerCase()) != -1 ||
			data.accountName.toLowerCase().indexOf(filter.toLowerCase()) != -1 ||
			data.orderDate.toLowerCase().indexOf(filter.toLowerCase()) != -1 ||
			(data.orderExpiry && data.orderExpiry.toLowerCase().indexOf(filter.toLowerCase()) != -1 ) ||
			data.sapOrderId.toLowerCase().indexOf(filter.toLowerCase()) != -1 ||
			data.poNumber.toLowerCase().indexOf(filter.toLowerCase()) != -1 ||
			data.customerPoNumber.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
		{
			return true;
		}
		else 
		{
			if ( data.unassigned )
			{
			 	let label = this.translateSvc.instant('orders_list.unassigned');
				if ( label.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
					return true;
			}
			if ( data.expired )
			{
				let label = this.translateSvc.instant('orders_list.expired');
				if ( label.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
					return true;
			}
			if ( data.expiring )
			{
				let label = this.translateSvc.instant('orders_list.expiring');
				if ( label.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
					return true;
			}
			if ( data.isPending )
			{
				let label = this.translateSvc.instant('orders_list.pending');
				if ( label.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
					return true;
			}
			if ( data.perpetual )
			{
				let label = this.translateSvc.instant('orders_list.perpetual');
				if ( label.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
					return true;
			}
			if ( data.undeployed )
			{
				let label = this.translateSvc.instant('orders_list.undeployed');
				if ( label.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
					return true;
			}
			if ( data.canActivate )
			{
				let label = this.translateSvc.instant('orders_list.activate');
				if ( label.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
					return true;
			}

			let unavailableLabel = this.translateSvc.instant('orders_list.unavailable');
			if (unavailableLabel.toLowerCase().indexOf(filter.toLowerCase()) != -1 && 
				(!data.sapOrderId  || !data.poNumber || !data.customerPoNumber)){
					return true;
			}
		}
		return false;
	};

	private requestOrdersList()
	{
		if ( !this.claims )
			return;

		return this.ordersService.getAllOrders( this.claims.accountId )
		.then( orders => this.removeFeatureRestrictedOrders(orders))
		.then( orders => {
			this.orders = orders;
			this.dataSource = new MatTableDataSource(this.processData(orders));
			this.setFilterPredicateAndSorting();
			this.spinnerService.hideSpinner();
		})
		.catch(err => {
			console.error("Error retrieving orders", err); //TBD - how to handle the error
			this.spinnerService.hideSpinner();
			if ( err.statusCode == 400 && 
				(err.body && err.body.message && err.body.message.indexOf("is no billing account")) > -1 )
			{
				console.log("This is not an error, this account does not have any orders");
				this.dataSource = new MatTableDataSource([]);
			} 
			else
			{
				this.errMsg = this.translateSvc.instant('orders_list.error_retrieving_orders');
				if ( err.body && err.body.message )
					this.errMsg += " " + err.body.message;

				this.showError = true;
			}
		});
	}

	async onEditOrder(row: OrderItem) {
		this.errMsg = '';
		this.showError = false;

		const dialogRef = this.dialog.open(OrderDetailsComponent, {
			autoFocus: false,
			data: {
				row: row,
				ordersList: this.orders
			}
		});

		dialogRef.afterClosed().subscribe( async result => {

			if ( result && result.complete == true && result.account == undefined ) //update the list because a change occurred
			{
				// go right to the update
				this.spinnerService.setSpinnerMessageByKey('app.loading');
				this.spinnerService.showSpinner();
				this.requestOrdersList();
			}
			// determine if there was an order action
			// if so, get the selected account from the result
			// check the account tags. Is there a corresponding integration for that license?
			// if so, continue on, if not, show the dialog to indicate they need an integration
			else if ( result && result.complete == true && result.account !== undefined ) 
			{
				if (result.action == 'deploy') {
					let requestOrdersListPromise = this.requestOrdersList(); // update the landing list in the background while the dialog is open
					this.dialog
						.open(OrderCompleteComponent, { data: { action: result.action, accountId: result.account.accountId }})
						.afterClosed().subscribe(result => {
							// update the landing list
							this.spinnerService.setSpinnerMessageByKey('app.loading');
							this.spinnerService.showSpinner();
							requestOrdersListPromise.then(() => {
								this.spinnerService.hideSpinner();
								// Once the list has been updated, check if the user wants to activate the order
								if (result && result.activate == true) {
									// if the user wants to activate the order, open the order details dialog
									let newRow = this.dataSource.data.find(order => order.id === row.id);
									if (newRow) {
										this.onEditOrder(newRow);
									} else {
										this.errMsg = this.translateSvc.instant('errors.couldnt-find-order');
										this.showError = true;
									}
								}
							});
						}
					);
				} else {
					// we need to check to see if the specific account has the integration required 
					// for the assigned license
					let tags = undefined;
					let hasIntegration = false;
					try{
						tags = await this.adminService.getAccountTags(result.account.accountId);
					}
					catch (err)
					{
						console.error("there was an issue requesting tags for account. Will assume no tags", result.account.accountId, err);
					}
					hasIntegration = this.determineIfAccountHasProperIntegrationForOrder(tags, row);

					if ( !hasIntegration )
					{
						const dialogRef2 = this.dialog.open(OrderCompleteComponent, { data: { action: result.action, accountId: result.account.accountId}} );
						dialogRef2.afterClosed().subscribe(result => {
							//update the landing list
							this.spinnerService.setSpinnerMessageByKey('app.loading');
							this.spinnerService.showSpinner();
							this.requestOrdersList();
						});
					}
					else
					{
						//update the landing list
						this.spinnerService.setSpinnerMessageByKey('app.loading');
						this.spinnerService.showSpinner();
						this.requestOrdersList();
					}
				}
			}
		});
	}

	determineIfAccountHasProperIntegrationForOrder( tags: any, order: OrderItem ): boolean
	{
		if ( tags == undefined || tags.products == undefined || tags.products.length == 0 )
			return false;

		if ( !order )
			return true;
		
		if ( !order.licenses || order.licenses.length == 0 )
			return true;

		for ( var i = 0; i < order.licenses.length; i++ )
		{
			// first non match means we return false
			// not specific about the type of license
			let product = this.findProductForLicense(order.licenses[i].productId);
			if ( product != undefined )
			{
				if ( tags.products.indexOf(product) == -1  )
					return false;
			}
		}

		return true;
	}

	findProductForLicense( productId: string ): string
	{
		for ( var i = 0; i < IntegToLicenseCrossreference.length; i++ )
		{
			for ( var j = 0; j < IntegToLicenseCrossreference[i].licenseIdInBilling.length; j++ )
			{
				if ( IntegToLicenseCrossreference[i].licenseIdInBilling[j] == productId )
					return IntegToLicenseCrossreference[i].integProductCode;
			}
		}
		return undefined;
	}
	setCurrentFilter(event) {
		let currentFilter = this.dataSource.filter;
		this.dataSource = new MatTableDataSource(this.processData(this.orders));
		this.filterOrders();
		this.setFilterPredicateAndSorting();
		if (currentFilter) {
			this.onSearch(currentFilter);
		}
	}

	setFilterPredicateAndSorting() {
		this.dataSource.filterPredicate = this.customSearch.bind(this);
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {
			   case 'subscriptionExpiry': return new Date(item.orderExpiry);
			   case 'resellerPONumber': return item['poNumber'];
			   case 'customerPONumber': return item['customerPoNumber'];
			   default: return item[property];
			}
		};
		this.dataSource.sort = this.sort;
	}

	onSearch(filterValue: any) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	filterOrders() {
		if (this.currentFilter.name !== 'all') {
			this.dataSource.data = this.dataSource.data.filter( (data: any) => {
				if (this.currentFilter.excludeExpired && data.expired) {
					return false;
				}
				return data[this.currentFilter.name] === this.currentFilter.value;
			});
		}
	}

	private processData(orders: OrderItem[]): OrderItem[] {
		if (orders.length > 0) {
			return orders
				.map((order) => {
					return {
						...order,
						badge:
							order.expired ? 'expired' :
							order.expiring ? 'expiring' :
							order.isPending ? 'pending' :
							order.unassigned ? 'assign' :
							order.undeployed ? 'deploy' :
							order.canActivate ? 'activate' :
							order.perpetual ? 'perpetual' :
							null,
					}
				});
		} else {
			return [];
		}
	}

	ngOnDestroy()
	{
		this.subscription?.unsubscribe();
	}

	addFeatureEnabledFilters() {
		if (this.featureToggleService.featureFlags.get(FeatureFlag.ALLOW_OSV_DIRECT_SALES)) {
			this.filters.push({ name: 'canActivate', value: true, excludeExpired: true });
			this.filters.push({ name: 'undeployed', value: true, excludeExpired: true });
		}
	}

	removeFeatureRestrictedOrders(orders: OrderItem[]): OrderItem[] {
		if (!this.featureToggleService.featureFlags.get(FeatureFlag.ALLOW_OSV_DIRECT_SALES)) {
			return orders.filter(order => !order.canActivate && !order.undeployed);
		}
		return orders;
	}
}
