import { Injectable } from "@angular/core";
import { PolicyTestResponse } from "@mitel/cloudlink-sdk/authentication";
import { BehaviorSubject, Subject } from "rxjs";
import { FeatureFlag, PolicyEffect } from "../enums/feature-flags";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class FeatureToggleService {
  public featureFlags: Map<FeatureFlag, boolean> = new Map();

  constructor(private authService: AuthService) {
    this.featureFlags.set(FeatureFlag.ALLOW_OSV_DIRECT_SALES, false);
    // Add more feature flags here as needed (default to false)
  }

  getTypedFeaturePolicies() {
    let policies: Map<FeatureFlag, {resource: string, action: string}> = new Map();
    this.featureFlags.forEach((value, featureFlag) => {
      policies.set(featureFlag, {
        resource: `https://mitel.io/auth/feature-flag/features/${featureFlag}`,
        action: "show",
      });
    });
    return policies;
  }

  async initPolicyTestFlags(): Promise<void> {
    let typedFeaturePolicies = this.getTypedFeaturePolicies();
    let feature = {
      resources: [
        ...typedFeaturePolicies.values(),
      ],
      types: ['feature-flag'] // required for new typed policies
    };

    await this.authService.testPolicy(feature)
      .then((response) => this.testTypedPolicyReply(typedFeaturePolicies, response))
      .catch((error) => console.error("Error getting policy for account", error))
  }

  testTypedPolicyReply(typedFeaturePolicies: Map<FeatureFlag, {resource: string, action: string}>, response: PolicyTestResponse) {
    if (response && response.results) {
      typedFeaturePolicies.forEach((typedFeaturePolicy, featureFlag) => {
        const featureResponse = response.results.find((res) => res.resource === typedFeaturePolicy.resource);
        if (featureResponse && PolicyEffect[featureResponse.effect] === PolicyEffect.ExplicitAllow) {
          this.featureFlags.set(featureFlag, true);
        }
      });
    }
  }
}
