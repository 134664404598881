<div class="ipad-width">
    <div mat-dialog-title class="title">
        <label *ngIf="this.order.expiring" class="expiring-order-badge">{{'order_details.order_expiry_time' | translate: {numdays: this.order.expiryDays} }}</label>

        <label *ngIf="this.order.expired" class="expired-order-badge">{{'orders_list.expired' | translate}}</label>
    
        <label *ngIf="this.order.isPending" class="pending-order-badge">{{'orders_list.pending' | translate}}</label>
        <label *ngIf="this.order.perpetual" class="perpetual-order-badge">{{'orders_list.perpetual' | translate}}</label>
        <div class="close-button">
            <button mat-icon-button id="confirm-close" attr.aria-label="{{ 'app.close_button' | translate }}" (click)="onClose()"><mat-icon>close</mat-icon>
            </button> 
        </div>

        <form-error-alert *ngIf="showFormError" class="form-alert" [errorMessage]="errMsg" [externalStyles]="errMsgStyle"></form-error-alert>
        <img class="files-icon" src="../assets/images/files.svg" />
    </div>
    <div mat-dialog-content class="mat-typography">
        <div class="subscriptionNumberlabel">
            <label>{{ 'order_details.subscription_number' | translate }}:&nbsp;{{order.subscriptionNumber}}</label>
        </div>
        <div *ngIf="showDetails==false">
            <label 
                id="seeLink"
                class="details-link clickable-pointer" 
                (click)="handleShowDetailsEvent()" 
                tabindex="0" 
                (keydown.enter)="$event.preventDefault(); handleShowDetailsEvent()">
                {{ 'order_details.see_additional_details' | translate }}
                <img src="../assets/images/arrow-down.png" class="Arrow-Dropdown clickable-pointer">
            </label>
            
        </div>

        <div *ngIf="showDetails==true">
            <div>
                <label 
                    id="closeLink"
                    class="details-link clickable-pointer" 
                    (click)="handleShowDetailsEvent()" 
                    tabindex="0"
                    (keydown.enter)="$event.preventDefault(); handleShowDetailsEvent()">
                    {{ 'order_details.hide_additional_details' | translate }}
                    <img src="../assets/images/arrow-up.png" class="Arrow-Dropdown clickable-pointer">
                </label>
                
            </div>
            <div class="detail-whole-line" [matTooltip]="resellerPOTooltip">
                <label class="order-detail-label">{{ 'order_details.po_number' | translate }}:&nbsp;</label>
                <label id="resellerPONumber" class="poNumberlabel">{{order.poNumber}}</label>
            </div>
            <div class="detail-whole-line" [matTooltip]="resellerSapIdTooltip">
                <label class="order-detail-label">{{ 'order_details.reseller_SAP_Id' | translate }}:&nbsp;</label>
                <label id="resellerSapId" class="poNumberlabel">{{order.resellerSapId}}</label>
            </div>
            <div class="detail-whole-line" [matTooltip]="trackingNumberTooltip">
                <label class="order-detail-label">{{ 'order_details.tracking_number' | translate }}:&nbsp;</label>
                <label id="trackingNumber" class="poNumberlabel">{{order.orderNumber}}</label>
            </div>
            <div class="detail-whole-line" [matTooltip]="customerPOTooltip">
                <label class="order-detail-label" >{{ 'order_details.customerPONumber' | translate }}:&nbsp;</label>
                <label id="customerPONumber" class="poNumberlabel" >{{order.customerPoNumber}}</label>
            </div>
            <div class="detail-whole-line" [matTooltip]="customerSapIdTooltip"> 
                <label class="order-detail-label">{{ 'order_details.customerSapId' | translate }}:&nbsp;</label>
                <label id="customerSapId" class="poNumberlabel">{{order.customerSapId}}</label>
            </div>
            <div class="detail-whole-line" [matTooltip]="sapOrderIdTooltip"> 
                <label class="order-detail-label">{{ 'order_details.sapOrderId' | translate }}:&nbsp;</label>
                <label id="sapOrderId" class="poNumberlabel">{{order.sapOrderId}}</label>
            </div>
            <div class="rectangle-copy-title"></div>
        </div>

        <div class="order-details-label">
            <label>{{ 'order_details.start_date' | translate }}:&nbsp;{{order.orderDate| date:'YYYY-MM-dd'}}</label>
            <label class="float-right poNumberlabel">{{ 'order_details.term' | translate }}:&nbsp;{{order.term}}</label>
        </div>
        <div class="whole-line" [matTooltip]="nameTooltip">
            <label class="order-detail-label">{{ 'order_details.order_assigned_to' | translate }}:&nbsp;</label>
            <label class="accountLabel" *ngIf="order.unassigned">{{ ('orders_list.unassigned' | translate).toLowerCase()}}</label>
            <label 
                id="accountNameLabel" 
                class="accountLabel" 
                *ngIf="order.unassigned==false">
                {{order.accountName}}
            </label>
        </div>
        <div class="rectangle-copy-title"></div>
        <app-order-licenses [orderDetails]="order"></app-order-licenses>
    </div>

    <div mat-dialog-actions *ngIf="this.order.unassigned && !this.order.expired">
        <app-select-account
            [accountFormGroup]="assignSection"
            [excludeAccount]="this.order.accountId"
            (selectedAccountChanged)="this.handleSelectedAccount($event)">
        </app-select-account>
    </div>

    <div mat-dialog-actions align="end">
        <button
            *ngIf="this.order.unassigned && !this.order.expired"
            mat-stroked-button
            app-rbac category="accounts" [actions]="['add']" [mode]="restrictedAccess ? 'readonly' : null"
            class="snap-left" 
            [disabled]="restrictedAccess"
            attr.aria-label="{{ 'order_details.create_new_account' | translate }}" 
            (click)="onCreateNewAccount($event)">
                {{ 'order_details.create_new_account' | translate }}
        </button>
        <button mat-stroked-button mat-dialog-close attr.aria-label="{{ 'app.close' | translate }}" (click)="onClose()">{{ 'app.close_button' | translate }}</button>
        <button 
            *ngIf="this.order.unassigned && !this.order.expired"
            mat-flat-button
            class="btn"
            [disabled]="disableAssignButton || restrictedAccess"
            color=primary
            attr.aria-label="{{ 'order_details.assign_button' | translate }}"
            (click)="onAssignOrder($event)"
            id="assign-order-button">
                {{ 'order_details.assign_button' | translate }}
        </button>
        <button 
            *ngIf="this.order.undeployed && !this.order.expired"
            mat-flat-button
            class="btn"
            [disabled]="restrictedAccess"
            color=primary
            attr.aria-label="{{ 'order_details.deploy_button' | translate }}"
            (click)="onDeployOrder()"
            id="deploy-order-button">
                {{ 'order_details.deploy_button' | translate }}
        </button>
        <button 
            *ngIf="this.order.canActivate && !this.order.expired"
            mat-flat-button
            class="btn"
            [disabled]="restrictedAccess"
            color=primary
            attr.aria-label="{{ 'order_details.activate_button' | translate }}"
            (click)="onActivateOrder()"
            id="activate-order-button">
                {{ 'order_details.activate_button' | translate }}
        </button>
    </div>
</div>