import { Injectable } from '@angular/core';
import { ClHeaderComponent } from '@mitel/cloudlink-console-components';
import { TranslateService } from '@ngx-translate/core';
import { ParentCommsService } from './parent-comms.service';

@Injectable({
	providedIn: 'root'
})
export class ErrorsHandlerService {

	constructor(
		private clHeader: ClHeaderComponent,
		private parentCommsService: ParentCommsService,
		private translateService: TranslateService
	) { }

	handleError(error: any): void {
		console.error("handleError: ", error);

		if (error.statusCode) {
			switch (error.statusCode) {
				case 403: {
					console.log('Function: handleError: ', "Unauthorized 403");
					this.clHeader.logoutUser();
					break;
				}
				case 401: {
					console.log('Function: handleError: ', "Unauthorized 401");
					if (this.parentCommsService.tellParentToRedirectToLogin() == false)
						this.clHeader.redirectToLogin(); // let session management figure it out
					break;
				}
			}
		}

		if (error instanceof Error) {
			console.log('Function: handleError, : ', 'instance of Error');
			if (error.message === 'No token passed and no token could be found in localstorage. Please login and / or pass a valid token') {
				if (this.parentCommsService.tellParentToRedirectToLogin() == false)
					this.clHeader.redirectToLogin(); // let session management figure it out
			}
		}

	}

	getErrorMessageFromError(error: any): string {
    if (!error) {
      return undefined;
    }

    let prefixMsg = '';

    if (error?.statusCode === 504) {
      prefixMsg = this.translateService.instant('app-errors.504-general-message') + ' ';
    }

    return prefixMsg + (error?.body?.error?.msg
      ?? error.message
      ?? error.msg
      ?? error.body?.msg
      ?? error.body?.message
      ?? error.statusText
      ?? error.statusCode?.toString()
      ?? error);
  }
}
