<div class="wrapper">
  <div *ngIf="showError" class="bottom-spacing">
      <form-error-alert [errorMessage]="errMsg" [externalStyles]="errMsgStyle"></form-error-alert>
  </div>
  <div class="title-container">
    <div class="title">{{ 'orders_dashboard.title' | translate }}</div>
      <mat-form-field class="filter-wrapper form-field--thin legacy-filter-dropdown">
      <mat-select 

        [(value)]="currentFilter" 
        name="filter"     
        (selectionChange)="setCurrentFilter($event)">
          <mat-select-trigger>
              {{'orders_dashboard.filter_' + currentFilter.name | translate}}&nbsp;({{dataSource.filteredData.length}})
          </mat-select-trigger>
          <mat-option class="filter-option" *ngFor="let filter of filters" [value]="filter">
            {{ 'orders_dashboard.filter_' + filter.name | translate }}
          </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row form-group">
    <div class="col-6 search-wrapper">
      <mat-form-field class="search-field">
        <mat-icon class="prefix-icon" matPrefix svgIcon="search_app"></mat-icon>
        <input matInput
          (keyup)="onSearch($event.target.value)"
          [placeholder]="'orders_dashboard.search_orders' | translate">
      </mat-form-field>
    </div>
  </div>
  <div class="mat-elevation-z8 table-container">
    <table mat-table
           [dataSource]="dataSource"
           matSort
           matSortActive="sapOrderId"
           matSortDisableClear
           matSortDirection="asc"
           matSortStart="asc"
    >

      <!-- SAP Order Id Column -->
      <ng-container matColumnDef="sapOrderId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'order_details.sapOrderId' | translate | uppercase}}</th>
        <td mat-cell *matCellDef="let row" [ngClass]="[!row.sapOrderId ? 'unassigned-order': '']">
          {{row.sapOrderId ? row.sapOrderId : ('orders_list.unavailable' | translate) }} 
        </td>
      </ng-container>

      <!-- Subscription Number Column -->
      <ng-container matColumnDef="subscriptionNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'orders_list.subscriptionNumber' | translate | uppercase}}</th>
        <td mat-cell *matCellDef="let row">{{row.subscriptionNumber}} </td>
      </ng-container>

      <!-- Reseller PO Number Column -->
      <ng-container matColumnDef="resellerPONumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'order_details.po_number' | translate | uppercase}}</th>
        <td mat-cell *matCellDef="let row" [ngClass]="[!row.poNumber ? 'unassigned-order': '']">
          {{row.poNumber ? row.poNumber : ('orders_list.unavailable' | translate) }} 
        </td>
      </ng-container>

       <!-- Cutomer PO Number Column -->
       <ng-container matColumnDef="customerPONumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'order_details.customerPONumber' | translate | uppercase}}</th>
        <td mat-cell *matCellDef="let row" [ngClass]="[!row.customerPoNumber ? 'unassigned-order': '']">
          {{row.customerPoNumber ? row.customerPoNumber : ('orders_list.unavailable' | translate) }} 
        </td>
      </ng-container>

      <!-- Assigned Account Column -->
      <ng-container matColumnDef="accountName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'orders_list.accountName' | translate | uppercase}}</th>
        <td mat-cell *matCellDef="let row" [ngClass]="[row.unassigned || row.undeployed ? 'unassigned-order': '']"> 
          {{row.unassigned || row.undeployed ? ('orders_list.unassigned' | translate) : row.accountName}} 
        </td>
      </ng-container>

      <!-- Subscription Expiry Column -->
      <ng-container matColumnDef="subscriptionExpiry">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'orders_list.subscriptionExpiry' | translate | uppercase}}</th>
        <td mat-cell *matCellDef="let row" [ngClass]="[row.expiring == true ? 'expiring-order': '']">
          <div class="expiry-divs">
            <div class="expiry-divs">
              {{row.orderExpiry| date:'YYYY-MM-dd'}} 
            </div>
            <div *ngIf="row.badge"
              class="order-badge"
              [ngClass]="row.badge + '-order-badge'">
              {{ 'orders_list.' + row.badge | translate | uppercase }}
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
      <tr 
        mat-row 
        *matRowDef="let row; columns: displayedColumns;" 
        class="clickable-pointer"
        (click)="onEditOrder(row)" 
        [attr.tabindex]="0"
        (keydown.enter)="$event.preventDefault(); onEditOrder(row)">
      </tr>
    </table>
    <div 
      class="no-orders-state" 
      *ngIf="dataSource.data.length === 0 && isSpinnerShown() == false">{{'orders_list.no_orders_found' | translate}}
    </div>
  </div>
</div>



