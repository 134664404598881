  	<div class="ipad-width full-dialog-properties">
        <div class="title">
            <div *ngIf="showFormError">
                <form-error-alert [errorMessage]="errMsg" [externalStyles]="errMsgStyle"></form-error-alert>
            </div>
            <div class="float-right-child">
                <button mat-icon-button id="confirm-close" attr.aria-label="{{ 'app.close_button' | translate }}" (click)="onClose()"><span aria-hidden="true"> &times;</span>
                    </button> 
            </div>
            <div class="center-child">
                <img class="check-icon" src="../assets/images/check-circ-solid.svg" />
            </div>
        </div>
    </div>
    <div class="ipad-width order-info-container bottom-spacing">
        <div class="orderAddedlabel">
            <label *ngIf="data.action == 'assign'">{{ 'order_complete.order-added' | translate }}</label>
            <label *ngIf="data.action == 'activate'">{{ 'order_complete.order-activated' | translate }}</label>
            <label *ngIf="data.action == 'deploy'">{{ 'order_complete.order-deployed' | translate }}</label>
        </div>
        <div class="instructionslabel">
            <label *ngIf="data.action != 'deploy'">{{ 'order_complete.enable-integration' | translate }}</label>
            <label *ngIf="data.action == 'deploy'">{{ 'order_complete.activate-order-instructions' | translate }}</label>
        </div>
        <div class="button-div">
            <button 
                mat-button 
                class="btn btn-default" 
                attr.aria-label="{{ 'order_complete.go-to-account' + ( data.action == 'deploy'? '' : '-integrations') | translate }}" 
                (click)="onForwardToAccount()">
                    {{ 'order_complete.go-to-account' + ( data.action == 'deploy'? '' : '-integrations') | translate }}
            </button>
            <button
                *ngIf="data.action == 'deploy'"
                mat-button 
                class="btn btn-default" 
                attr.aria-label="{{ 'order_complete.activate-order' | translate }}" 
                (click)="onActivateOrder()">
                    {{ 'order_complete.activate-order' | translate }}
            </button>
        </div>
    </div>
  
    <div class="rectangle-copy"></div>
    <div class="actions-spacing">
        <div class="float-right">
            <button 
                mat-button 
                class="btn btn-default cancel" 
                attr.aria-label="{{ 'app.close' | translate }}" 
                (click)="onClose()">{{ 'app.close_button' | translate }}
            </button>
        </div>
    </div>
