import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { environment } from '../environments/environment';
import { UserClaims } from '@mitel/cloudlink-sdk';
import { ClHeaderComponent, ClLogService } from '@mitel/cloudlink-console-components';
import { AppUserClaimsService } from './services/app-user-claims.service';
import { ParentCommsService } from './services/parent-comms.service';
import { AppInfoService } from './services/app-info.service';
import { AppStateService } from './services/app-state.service';
import { UserPermissionsService } from './services/user-permissions.service';

import { RBACAuthService } from './rbac/services/rbac-auth.service';
import { FeatureToggleService } from './services/feature-toggle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'Billing';
  claims: UserClaims;
  /* istanbul ignore next */ //can't change the values of environment files
  cloud: string = environment.cloud || '';
  
  constructor(
		private translateSvc: TranslateService,
		private clHeader: ClHeaderComponent,
		private router: Router,
    private appUserClaimsSvc: AppUserClaimsService,
    private clLog: ClLogService,
    private parentCommsService: ParentCommsService,
    private appInfoService: AppInfoService,
    private appStateService: AppStateService,
    private userPermissionsService: UserPermissionsService,
    private rbacAuthService: RBACAuthService,
    private featureToggleService: FeatureToggleService
  ){}

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {

    if (this.parentCommsService.tellParentToCheckSession() === false) {
      this.clHeader.checkValidSessionStatus();
    }
  }


  @HostListener('window:message', ['$event'])
	onMessage(event) {
    if ( event && event.data && event.data.type == "CL_LANGCHANGE" && event.data.data )
    {
      this.translateSvc.use(event.data.data);
    }
    else if ( event && event.data == "CL_UNLOAD" )
    {
      this.clHeader.logoutOnly();
    }
  }

  ngOnInit()
  {
    this.clHeader.setClientId(environment.clientId);
    this.clLog.setSourceContext("BillingApp");
    this.setupTranslationService();

  }

  private setupTranslationService()
  {
    const langRegex = new RegExp(
		  this.appInfoService.getLangCodes().join('|'),
		  'gi'
		);
    this.translateSvc.addLangs(this.appInfoService.getLangCodes());
    this.translateSvc.setDefaultLang('en');
    const browserLang =  this.translateSvc.getBrowserLang();
    this.clLog.debug('TS browser lang: ', this.translateSvc.getBrowserLang());
    if(browserLang == 'en'){
			const language = this.translateSvc.getBrowserCultureLang();
			this.translateSvc.use(language.match(/en-GB/) ? language : 'en');
		  }
		else
			this.translateSvc.use(browserLang.match(langRegex) ? browserLang : 'en');
  }

  async handleUserClaims(claims: UserClaims)
  {

    this.claims = claims;
    this.clLog.debug("Received claims from the header", claims);
    this.clLog.setAccountContext(claims.accountId);
    this.clLog.setUserContext(claims['userId']);
    await this.featureToggleService.initPolicyTestFlags(); // Call this before setting claims, as the child components listen for the claims to change to trigger their startup
		this.appUserClaimsSvc.setClaims(this.claims);

    if(claims.accountId != (claims as any).providerAccountId){ //providerAccountId is not in UserClaims interface
      //User does not belong to account.
      //This can happen if it's a Support Portal Global_Admin who assumes role into a partner prime account as Partner_Admin.
      //There may be a policy on the user to restrict the ui for read-only
      let restriction = await this.userPermissionsService.checkForSupportUiRestrictions();
      this.appStateService.setRestrictedAccess(restriction);

    }
    else{
      //User belongs to account, and route guard already checks for Partner_Admin.
      //No need or requirement to restrict Ui at this time
      this.appStateService.setRestrictedAccess(false);
      let user = await this.getClaimsUserWithTags();

    }
  }

  async getClaimsUserWithTags(){
		let claims: any = this.claims; //any since UserClaims interface does not include userId
		let user: any = undefined;
		if(claims && claims.userId){
			try {
				user = await this.rbacAuthService.getUser(claims.userId, 'tags');
			} catch (err) {
				console.error('Could not get claims user', claims.userId);
			}
		}
		console.log('Claims User: ', user);
		return user;
	}

  handleGoHome() 
  {
    this.router.navigateByUrl('/');
  }

  handleInvokingUrl(url: string)
  {
    if ( url && url.length > 0 ) 
    {
      if ( url.indexOf('?') > 0 ) 
      {
        //there are query parameters
        let theParams = url.split('?')[1];
        if ( theParams != null ) {
          var langCode = theParams.indexOf('lang=') >= 0 ? theParams.split('lang=')[1].split('&')[0] : '';
          if ( langCode )
            this.translateSvc.use(langCode);
          
          var accountId = theParams.indexOf('accountid=') >= 0 ? theParams.split('accountid=')[1].split('&')[0] : '';
          if( accountId) {
            this.parentCommsService.setCurrentAccountIdToAssignOrder(accountId);
          }

          var returnVal = theParams.indexOf('return=') >= 0 ? theParams.split('return=')[1].split('&')[0] : '';
          if( returnVal ) {
            this.parentCommsService.setReturnToOrigin(returnVal);
          }
        }
      }
    }
  }

}
